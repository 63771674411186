<template>
    <div class="main-container" style="padding-right:0;padding-left:0">
        <section class="bg-gray pt-4">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xl-8 col-md-7" style="padding-right: 0;padding-left: 0;">
                        <iframe title="Byte-Include" allowfullscreen="" scrolling="no" width="100%" height="auto" style="width:100vw;height:80vh" data-v-b1d34210="" v-bind:src="url_data" frameborder="0"></iframe>
                            <!-- <template v-for="publish in Content" :key="publish.id">                   -->
                                    <!-- <ul class="video-meta">
                                        <li>
                                            <i class="fas fa-eye"></i>{{publish.views}} مشاهدة </li>

                                        <li>
                                            <i class="fas fa-calendar-alt"></i>
                                            {{publish.upload_date}} </li>

                                    </ul>
                                    <div class="container-fluid">
                                        <div class="row mt-15 mb-15">
                                            <div class="col-xl-12 col-md-12">
                                                <div class="video-like">
                                                    <p>{{publish.title_ar}}</p>    
                                                </div>
                                            </div>
                                            <div class="col-sm-6 my-auto">

                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex content-creator justify-content-between">
                                        <div class="my-auto">
                                            <a href="#">
                                                <img :src="publish.cp_img" alt="ِAsim"></a>
                                            <span class="pl-10"> إ / {{publish.cp_name}}</span>
                                        </div>

                                    </div> -->
                                    <!-- </template> -->
                                </div>
                                <div class="col-xl-4 col-md-5" style="padding-right: 0;padding-left: 0;">
                                    <div id="viewtube_related_videos-2"  class="widget widget-video widget_viewtube_related_videos">
                                        <ul class="sidebar-related-video">
                                            <li style="box-shadow: 0px 2px 1px #7d76766b" v-for="publish in CategoryContent" :key="publish.id">
                                                <div class="related-video-thumb pointer">
                                                    <a @click="GoToContent(publish)">
                                                        <img :src="publish.carsoul_img" alt="Image"> 
                                                    </a>
                                                </div>
                                                <div class="related-video-content pointer">
                                                    <p>
                                                        <a @click="GoToContent(publish)">{{publish.title_ar}}</a>
                                                    </p>
                                                    <ul>
                                                        <li>
                                                            <a class="author" @click="GoToContent(publish)">
                                                                أ / {{publish.cp_name}} </a>
                                                        </li>
                                                        <li>
                                                            <div class="d-flex video-meta-bottom" dir="ltr">
                                                                Mar {{publish.year}}  </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from '@vue/reactivity';
import { useRouter } from "vue-router"
import { useCookie } from 'vue-cookie-next'
import { watchEffect } from '@vue/runtime-core';

export default {
    name:'AppContent',
    props :{
        content_id:String,
    },
    data() {
        return {
            url_data: null
        };
    },
    mounted() {
        this.url_data='http://courseatk.com/VideoPage/video.php?ID='+this.$route.params.content_id+'&MDN='+this.$cookie.getCookie("msisdn");
        },
   async setup(props) {
    const router = useRouter();
    const cookie = useCookie();
    // const toast = useToast();
    const CategoryContent = ref([]);

    watchEffect(() => {
            if (cookie.isCookieAvailable("status") && cookie.isCookieAvailable("msisdn")) {
                HTTP.post(`CheckLogin.php?username=`+cookie.getCookie("msisdn")+ `&serviceId=2222&operatorID=2`).then((res) => {
                    if (res.data.status === 1 ) {
                        return;
                    } else {
                        cookie.removeCookie("remming_minutes");
                        cookie.removeCookie("msisdn");
                        cookie.removeCookie("status");
                        router.push("/Login");
                    }
                });
                
            } else {
                router.push("/Login");
            }
        });  


    
    try {
      await HTTP.get(`GetContentByCategory.php?LIMIT=10&OFFSET=0&CID=`+props.content_id).then((res) => {
        CategoryContent.value = res.data.Content; 
      });
    } catch (err) {
      console.log(err);
    }


    const GoToContent = (publish) => {
        if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("status")) {
          router.push({ name: "Contents", params: { content_id: publish.id } });
        } else {
            router.push("/Login");
        //   window.open("http://sudanipay.com/subscription/courseatk/courseatk", "_self");
        }
    };
  
    return { CategoryContent ,GoToContent};
    
  },

}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>